<div class="es-gateway" *transloco="let transl">
  <header class="es-gateway-header">
    <div class="header-inner">
      <div class="es-logo">
        <img
          class="logo-clario"
          src="/assets/images/clario-new-logo.svg"
          alt="Clario-logo"
        />
      </div>
      <div class="product-name">
        {{ transl('user.account.title_proper_case') }}
      </div>
      <div class="ellipses-1"></div>
      <div class="ellipses-2"></div>
      <div class="ellipses-3"></div>
    </div>
  </header>
  <div (window:resize)="onResize()" *transloco="let transl">
    
    <div *ngIf="!isValidResolution" class="platform-error-container">
      <div class="es-content-box">
        <div
          class="content es-panel es-custom-panel"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <mat-icon
            class="icon"
            aria-hidden="false"
            aria-label="platform error"
            >error_outline</mat-icon
          >
          <span class="text">{{
            transl(errorMessageKey, { fallback: errorMessage })
          }}</span>
          <span class="text">{{
            transl(resolutionMessageKey, { fallback: resolutionMessage })
          }}</span>
        </div>
      </div>
    </div>
    <div [hidden]="!isValidResolution">
      <ng-container *ngIf="{ value: isLoading$ | async } as loadingContext">
        <ng-container *ngIf="loadingContext.value">
          <vt-ui-loading-overlay></vt-ui-loading-overlay>
        </ng-container>

        <ng-container
          *ngIf="{ value: participantType$ | async } as participantContext"
        >
          <ng-container *ngIf="participantContext.value === 'subject'">
            <ng-container
              *ngTemplateOutlet="
                pageHeaderTempl;
                context: { services: visitServices$ | async }
              "
            ></ng-container>

            <ng-container
              *ngTemplateOutlet="pageMainContentTempl"
            ></ng-container>
          </ng-container>
        </ng-container>

        <ng-template #pageHeaderTempl let-services="services">
          <ng-container
            *ngIf="{ value: visitIsReady$ | async } as visitContext"
          >
            <vt-visit-header
              [showControls]="true"
              [showSelectedServiceControl]="
                services === 'all' &&
                vidyoCanRender &&
                (hasDeviceConnection$ | async)
              "
              [participantType]="participantType$ | async"
              [customWelcomeClass]="
                !visitContext.value &&
                !isVisitEnded &&
                (participantType$ | async) === 'subject'
                  ? 'customWelcomeClass'
                  : ''
              "
              [selectedServiceView]="selectedServiceView"
              [selectedServiceState]="selectedServiceState"
              [emulationDeviceConnected]="connectedToDeviceSession$ | async"
              (toggleService)="toggleServiceView()"
            >
              <button
                rightMenu
                *ngIf="
                  !visitContext.value &&
                  !isVisitEnded &&
                  (participantType$ | async) === 'subject'
                "
                class="es-button logoutBtn"
                aria-label="Example icon-button with more horizontal icon"
                [autofocus]="false"
                (click)="navigateToLogin()"
              >
                {{ transl('user.account.logout') }}
              </button>
            </vt-visit-header>
          </ng-container>
        </ng-template>

        <ng-template #pageMainContentTempl>
          <ng-container
            *ngIf="visitIsReady$ | async; else waitingForVisitTempl"
            [ngTemplateOutlet]="activeVisitContentTempl"
          ></ng-container>
          <ng-template #waitingForVisitTempl>
            <vt-visit-welcome
              *ngIf="(visitIsReady$ | async) !== true && !isVisitEnded"
            ></vt-visit-welcome>
            <ng-container
              *ngIf="{ value: hasVisitEnded$ | async } as visitEndContext"
            >
              <vt-visit-end-sheet
                *ngIf="visitEndContext.value && !showSocketFailedSheet"
                [timedOut]="hasVisitTimedOut$ | async"
                (closed)="navigateToLogin()"
              ></vt-visit-end-sheet>
            </ng-container>
          </ng-template>
          <ng-template #activeVisitContentTempl>
            <div
              [style.flex.grow]="1"
              [ngStyle]="{
                display: selectedServiceView === 'emulation' ? 'block' : 'none'
              }"
            >
              <div
                [ngStyle]="{
                  display:
                    selectedServiceView === 'emulation' ? 'block' : 'none'
                }"
                
              >
                <div *ngIf="isMobileDevice()" class="device-restriction-sheet text-center">
                  <vt-visit-mobile-restriction-sheet>
                  </vt-visit-mobile-restriction-sheet>
                </div>
                <vt-ui-emulation-device
                  [deviceId]="deviceId"
                  [hidden]="(connectedToDeviceSession$ | async) === false"
                  [errored]="emulationServiceLost$ | async"
                  [networkLost]="emulationConnectionLost$ | async"
                  *ngIf="!isMobileDevice()"
                >
                  
                </vt-ui-emulation-device>
                
                <vt-visit-content-sheet
                  *ngIf="
                    (connectedToDeviceSession$ | async) === false &&
                    (emulationServiceLost$ | async) !== true &&
                    !isMobileDevice()
                  "
                  class="visit-device-waiting"
                  data-testid="emulation-waiting-id"
                  [headerText]="transl('visit.emulation.assessment_form.title')"
                >
                  <ng-container cardBody>
                    <header class="es-card-header">
                      <h2>
                        {{ transl('visit.emulation.assessment_form.title') }}
                      </h2>
                    </header>
                    <div class="es-card-body">
                      <div class="es-content-box">
                        <div class="es-notification -es-info">
                          <span class="es-icon es-icon-info"></span>
                          <p id="body-text-paragraph-id">{{ transl(deviceControlStatusText) }}</p>
                        </div>

                        <p class="waiting-text">
                          {{
                            transl(
                              'visit.emulation.assessment_form.waiting_control_text'
                            )
                          }}
                        </p>
                        <div class="es-loading-indicator -es-visible">
                          <span class="es-loading-icon"></span>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </vt-visit-content-sheet>
              </div>
            </div>
            <section>
              <div
                [ngStyle]="{
                  display:
                    selectedServiceView === 'video-call' ? 'block' : 'none'
                }"
              >
                <div
                  #remoteView
                  id="{{ remoteViewId }}"
                  [ngClass]="vidyoHiddenClass"
                  class="visit-service-wrapper"
                ></div>
                <div
                  #localView
                  id="{{ localViewId }}"
                  data-testid="local-view-id"
                  [ngClass]="vidyoHiddenClass"
                  class="visit-call-local-preview-container"
                ></div>
                <div class="video-call-actions">
                  <ng-container *ngIf="showVideoCallView && vidyoCanRender">
                    <vt-visit-video-call-actions
                      *ngIf="{ value: mediaDeviceStates$ | async } as context"
                      [micIsEnabled]="context.value.mic"
                      [videoIsEnabled]="context.value.video"
                      (micButtonClick)="handleToggleMic(context.value.mic)"
                      (videoButtonClick)="
                        handleToggleVideo(context.value.video)
                      "
                      (switchButtonClick)="handleSwitchCamera()"
                    >
                    </vt-visit-video-call-actions>
                  </ng-container>
                </div>
              </div>
              <div class="give-back-control-button">
                <button
                  *ngIf="(connectedToDeviceSession$ | async)"
                  id="give-back-control-button-id"
                  (click)="handleGiveBackControl()"
                  class="es-button"
                >
                  <span class="es-icon es-icon-reply"></span
                  ><span>{{
                    transl('visit.subject.control_back_text')
                  }}</span>
                </button>
              </div>
              <ng-container
                *ngIf="{ value: askUserForPermissions$ | async } as context"
              >
                <vt-visit-device-permissions-sheet
                  *ngIf="context.value"
                  [@introFade]
                  [declinesBrowserPermission]="declinesBrowserPermission"
                  (acceptAccessDevices)="handleAcceptAccess()"
                  (declinesAccessDevices)="handleDeclinesAccessDevices()"
                  (leaveVisit)="navigateToLogin()"
                  (startAgain)="handleStartAgain()"
                ></vt-visit-device-permissions-sheet>
              </ng-container>
            </section>
          </ng-template>
          <ng-container *ngIf="showSocketFailedSheet">
            <vt-visit-socket-failed-sheet
              [@introFade]
              [failedVisitService]="failedVisitService"
              (acknowledged)="navigateToLogin()"
            ></vt-visit-socket-failed-sheet>
          </ng-container>
        </ng-template>
      </ng-container>
    </div>
  </div>
</div>
