<div class="es-gateway" *transloco="let transl">
  <!-- Loading bar overlay -->
  <vt-ui-loading-overlay *ngIf="isLoading$ | async"></vt-ui-loading-overlay>

  <!-- Header -->
  <header class="es-gateway-header">
    <div class="header-inner">
      <div class="es-logo">
        <img class="logo-clario" src="/assets/images/clario-new-logo.svg" alt="Clario-logo">
      </div>
      <div class="product-name">{{ transl('user.account.title_proper_case') }}</div>
      <div class="ellipses-1"></div>
      <div class="ellipses-2"></div>
      <div class="ellipses-3"></div>
    </div>
  </header>

  <!-- Language select drop down -->
  <div fxLayout="row" fxLayoutAlign="flex-end" class="es-lang-select">
    <vt-ui-language-select class="top-right"></vt-ui-language-select>
  </div>

  <!-- Forgot Password screen START -->
  <main class="es-gateway-body">
    <section *transloco="let transl" class="reset-password-layout es-layout-columns -es-2-column es-gateway-column -es-flex-horizontal">
      <div class="es-column">
        <div class="es-content-box">
          <div class="es-panel panel-padded">
            <div class="es-form">
              <div class="es-content-box">
                <h2>{{ transl('user.account.resend_password') }}</h2>
              </div>
              <div class="es-content-box" *ngIf="!resendEmailConfirmed">
                <div class="es-inline-message inline-message-reset-password">
                  <span class="es-icon es-icon-info-circle"></span>
                  <p>{{ transl('text.resend_password_text') }}</p>
                </div>
              </div>
              <ng-container *ngIf="resendEmailConfirmed; then confirmedTempl; else resendTempl">
              </ng-container>
              <div class="es-toolbar" *ngIf="!resendEmailConfirmed">
                <ul class="es-right">
                  <li class="es-toolbar-item">
                    <button class="es-button -es-primary" id="submitButtonId"
                      (click)="handleSubmitClick()">
                      {{ transl('general.submit') }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-template #confirmedTempl>
        <div class="es-content-box">
          <h4>{{ transl('text.resend_password_confirmed.paragraph_one') }}</h4>
        </div>
        <div class="es-content-box">
          <div class="es-inline-message inline-message-reset-password">
            <span class="es-icon es-icon-info-circle"></span>
            <div>
              <p>{{ transl('text.resend_password_confirmed.paragraph_two') }}</p>
              <ul>
                <li>{{ transl('text.resend_password_confirmed.options.one') }}</li>
                <li>{{ transl('text.resend_password_confirmed.options.two') }}</li>
                <li>{{ transl('text.resend_password_confirmed.options.three') }}</li>
                <li>{{ transl('text.resend_password_confirmed.options.four') }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="es-toolbar">
          <ul class="es-right">
            <li class="es-toolbar-item">
              <button class="es-button -es-primary" (click)="handleBackClick()">
                {{ transl('general.go_back_text') }}
              </button>
            </li>
          </ul>
        </div>
      </ng-template>

      <ng-template #resendTempl>
        <form class="form" [formGroup]="formGroup" class="form-spaced">
          <div class="es-form-field">
            <label class="es-label -es-required">{{transl('user.account.login_username_placeholder')}}</label>
            <input id="userNameId" autofocus formControlName="username" type="text" class="es-input" [ngClass]="{'-es-invalid': 
                  (formGroup.controls.username.hasError('required') ||
                  usernameHasLengthError) &&
                  formGroup.controls.username.touched
                }" [placeholder]="transl('user.account.login_username_placeholder')" />
            <div id="requiredErrorId" *ngIf="formGroup.controls.username.hasError('required') &&
                formGroup.controls.username.touched" class="es-form-message -es-invalid">
              <div class="error-icon"></div>
              <p>{{ transl('error.resend_password.incorrect_username') }}</p>
            </div>
            <div id="usernameLengthError" *ngIf="usernameHasLengthError && !usernameRequiredError"
              class="es-form-message -es-invalid">
              <div class="error-icon"></div>
              <p>{{ transl('error.resend_password.incorrect_username_length') }}</p>
            </div>
          </div>
        </form>
      </ng-template>

    </section>
  </main>
  <!-- Forgot Password screen END -->
</div>