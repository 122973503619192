import { Inject, Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { Client, ClientVersion } from '@virtual-trials-workspace/models';

import { WINDOW } from '../window/window.providers';

export const IOS_PLATFORM_CODE = 'IOS_PLATFORM';
export const ANDROID_PLATFORM_CODE = 'ANDROID_PLATFORM';
export const DEFAULT_PLATFORM_CODE = 'DEFAULT_PLATFORM';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  private readonly chromeVersionRegex = /Chrome\/([0-9]+\.?){1,4}/;
  private readonly chromeIosVersionRegex = /CriOS\/([0-9]+\.?){1,4}/;

  private readonly safariVersionRegex = /Version\/([0-9]+\.?){1,4}/;
  private readonly safariRegex = /Safari\/([0-9]+\.?){1,4}/;
  private readonly safariName = 'Safari';

  private readonly chromeName = 'Google Chrome';

  private get userAgent(): string {
    return this.window.navigator.userAgent;
  }

  constructor(
    private platform: Platform,
    @Inject(WINDOW) private window: Window
  ) {}

  isChrome = (): boolean => {
    return (
      ((this.platform.BLINK && this.userAgent.indexOf('Chrome') >= 0) ||
        (this.platform.WEBKIT && this.userAgent.indexOf('CriOS') >= 0)) &&
      !(this.userAgent.indexOf('SamsungBrowser') >= 0) &&
      !(
        this.userAgent.indexOf('OPR') >= 0 ||
        this.userAgent.indexOf('Opera') >= 0
      ) &&
      this.userAgent.indexOf('Edg') < 0
    );
  };

  isSafari = (): boolean => {
    return (
      ((this.platform.SAFARI && this.userAgent.indexOf('Safari') >= 0) ||
        (this.platform.WEBKIT && this.userAgent.indexOf('Safari') >= 0)) &&
      !(this.userAgent.indexOf('SamsungBrowser') >= 0) &&
      !(
        this.userAgent.indexOf('OPR') >= 0 ||
        this.userAgent.indexOf('Opera') >= 0
      ) &&
      this.userAgent.indexOf('Edg') < 0
    );
  };

  getClient = (): Client => {
    if (this.isChrome()) {
      return this.getChromeClient();
    } else if(this.isSafari()) {
      return this.getSafariClient();
    } 

    throw Error('Unknown client');
  };

  getPlatformCode = (): string => {
    if (this.platform.isBrowser) {
      if (this.platform.ANDROID || this.userAgent.indexOf('SamsungBrowser') > -1) {
        return ANDROID_PLATFORM_CODE;
      }

      if (this.platform.IOS) {
        return IOS_PLATFORM_CODE;
      }
    }
    return DEFAULT_PLATFORM_CODE;
  };

  private getChromeClient = (): Client => {
    const regexToRun = this.platform.BLINK
      ? this.chromeVersionRegex
      : this.platform.WEBKIT
      ? this.chromeIosVersionRegex
      : null;
    const chromeVersionMatches = this.userAgent.match(regexToRun);
    const chromeVersionStr = chromeVersionMatches.find((m) =>
      regexToRun.test(m)
    );
    const versionStr = chromeVersionStr.split('/').pop();
    const version = this.parseToVersion(versionStr);

    return { name: this.chromeName, version };
  };

  private getSafariClient = (): Client => {
    const regexToRun = this.platform.SAFARI && this.platform.WEBKIT
      ? this.safariVersionRegex
      : null;
    const safariNameMatches = this.userAgent.match(this.safariRegex);
    const safariVersionMatches = this.userAgent.match(regexToRun);
    const safariVersionStr = safariVersionMatches.find((m) =>
      regexToRun.test(m)
    );
    const safariNameStr = safariNameMatches.find((m) =>
      this.safariRegex.test(m)
    );
    const nameStr = safariNameStr.split('/').pop();
    const versionStr = safariVersionStr.split('/').pop();
    const version = this.parseToVersion(versionStr);

    return { name: this.safariName, version };
  };


  private parseToVersion = (value: string): ClientVersion => {
    const tmp = value.split('.');
    const major = tmp[0] ? +tmp[0] : 0;
    const minor = tmp.length >= 2 && !!tmp[1] ? +tmp[1] : 0;
    const patch = tmp.length >= 3 && !!tmp[2] ? +tmp[2] : 0;

    return new ClientVersion(major, minor, patch);
  };
}
