<div class="es-gateway" *transloco="let transl">
  <!-- Loading bar overlay -->
  <vt-ui-loading-overlay *ngIf="isLoading$ | async"></vt-ui-loading-overlay>
  
  <!-- background -->
  <div class="es-gateway__bg">
    <img class="logo-clario" src="/assets/images/clario-login-bg.png" alt="Clario-logo">
  </div>

  <!-- Header -->
  
  <header class="es-gateway-header">
    <div class="header-inner">
      <div class="es-logo">
        <img class="logo-clario" src="/assets/images/clario-new-logo.svg" alt="Clario-logo">
      </div>
      <div class="product-name">{{ transl('user.account.title_proper_case') }}</div>
      <div class="ellipses-1"></div>
      <div class="ellipses-2"></div>
      <div class="ellipses-3"></div>
    </div>
  </header>
  
  <!-- Language select drop down -->
  <div fxLayout="row" fxLayoutAlign="flex-end" class="es-lang-select">
    <vt-ui-language-select class="top-right"></vt-ui-language-select>
  </div>
  
  <!-- LOGIN screen START-->
    <main class="es-gateway-body">
      <vt-web-login-form
        [buildVersion]="buildVersion"
        [errorMessage]="
          errorCode && errorCode === INVALID_CREDENTIALS_ERROR_CODE
            ? transl('error.login.invalid_credentials')
            : errorCode && errorCode === ACCOUNT_LOCK_ERROR_CODE
            ? transl('error.login.account_locked')
            : undefined
        "
        [formGroup]="formGroup"
        [submitButtonIsEnabled]="!disableButton"
        (activateAccountClick)="handleActivateAccountClick()"
        (forgotPasswordClick)="handleForgotPasswordClick()"
        (submitFormClick)="handleSubmitFormClick()"
      > 
      </vt-web-login-form>
    </main>
  <!-- LOGIN screen END -->
</div>
