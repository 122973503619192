<div class="es-gateway" *transloco="let transl">
  <!-- Loading bar overlay -->
  <vt-ui-loading-overlay *ngIf="isLoading$ | async"></vt-ui-loading-overlay>
  
  <!-- Header -->
  
  <header class="es-gateway-header">
    <div class="header-inner">
      <div class="es-logo">
        <img class="logo-clario" src="/assets/images/clario-new-logo.svg" alt="Clario-logo">
      </div>
      <div class="product-name">{{ transl('user.account.title_proper_case') }}</div>
      <div class="ellipses-1"></div>
      <div class="ellipses-2"></div>
      <div class="ellipses-3"></div>
    </div>
  </header>
  
  <!-- Language select drop down -->
  <div fxLayout="row" fxLayoutAlign="flex-end" class="es-lang-select">
    <vt-ui-language-select class="top-right"></vt-ui-language-select>
  </div>
  
  <!-- LOGIN screen START-->
    <main class="es-gateway-body">

      <section
        *transloco="let transl"
        class="es-custom-layout es-layout-columns -es-2-column es-gateway-column -es-flex-horizontal"
        >
        <div class="es-column es-disclaimer-column">
          <div class="es-content-box">
            <div class="es-panel es-custom-panel">
              <div class="es-form">
                <div class="es-content-box">
                  <div class="es-text-group">
                    <h2>{{ transl('disclaimer.title') }}</h2>
                    <div class="es-disclaimer-box">
                        <p class="es-disclaimer-text">{{ transl('disclaimer.subtitle') }}</p>
                          <div class="terms-container">
                            <div cdkScrollable class="terms-content">
                              <p>{{ transl('disclaimer.content.title') }}</p>
                              <p>
                                {{ transl('disclaimer.content.paragraph.one') }}
                              </p>
                              <p>
                                {{ transl('disclaimer.content.paragraph.two') }}
                              </p>
                              <p>
                                {{ transl('disclaimer.content.paragraph.three') }}
                              </p>
                              <p
                                [innerHTML]="
                                  transl('disclaimer.content.paragraph.four', {
                                    link1: clarioPrivacyPolicyLink,
                                    link2: ertPortalLink,
                                    link3: privacyMailAddress
                                  })
                                "
                              ></p>
                              <p>
                                {{ transl('disclaimer.content.paragraph.five') }}
                              </p>
                              <p>
                                {{ transl('disclaimer.content.paragraph.six') }}
                              </p>
                              <p>
                                {{ transl('disclaimer.content.paragraph.seven.title') }}
                              </p>
                              <ul>
                                <li>
                                  {{
                                    transl('disclaimer.content.paragraph.seven.option.one')
                                  }}
                                </li>
                                <li>
                                  {{
                                    transl('disclaimer.content.paragraph.seven.option.two')
                                  }}
                                </li>
                                <li>
                                  {{
                                    transl('disclaimer.content.paragraph.seven.option.three')
                                  }}
                                </li>
                                <li>
                                  {{
                                    transl('disclaimer.content.paragraph.seven.option.four')
                                  }}
                                </li>
                                <li
                                  [innerHTML]="
                                    transl('disclaimer.content.paragraph.seven.option.five', {
                                      link: vidyoPrivacyPolicyLink
                                    })
                                  "
                                ></li>
                                <li
                                  [innerHTML]="
                                    transl('disclaimer.content.paragraph.seven.option.six', {
                                      link: googlePrivacyPolicyLink
                                    })
                                  "
                                ></li>
                              </ul>
                              <p>
                                {{ transl('disclaimer.content.paragraph.eight') }}
                              </p>
                            </div>
                          </div> 
                    </div>
                    <div class="es-toolbar">
                      <ul class="es-right">
                        <li class="es-toolbar-item">
                          <button
                            id="declineButton"
                            class="es-button -es-danger"
                            (click)="handleDeclineTermsAndConditions()"
                          >
                            {{ transl('disclaimer.decline') }}
                          </button>
                        </li>
                        <li class="es-toolbar-item">
                          <button
                            id="acceptButton"
                            [disabled]="!hasScrolledToBottomOfPage"
                            class="es-button -es-primary"
                            (click)="handleAcceptTermsAndConditions()"
                          >
                            {{ transl('disclaimer.accept') }}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
</div>