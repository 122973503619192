<div class="es-gateway" *transloco="let transl">
    <header class="es-gateway-header" >
        <div class="header-inner">
        <div class="es-logo">
            <img class="logo-clario" src="/assets/images/clario-new-logo.svg" alt="Clario-logo">
        </div>
        <div class="product-name">{{ transl('user.account.title_proper_case') }}</div>
        <div class="ellipses-1"></div>
        <div class="ellipses-2"></div>
        <div class="ellipses-3"></div>
        </div>
    </header>
    <div fxLayout="row" fxLayoutAlign="flex-end" class="es-lang-select">
        <vt-ui-language-select [setUserLanguage]="false" class="top-right"></vt-ui-language-select>
    </div>
    <vt-ui-viewport-layout [minWidth]="minViewPortWidth" [minHeight]="minViewPortHeight">        
        <router-outlet content></router-outlet>
        <vt-ui-viewport-error error></vt-ui-viewport-error>
    </vt-ui-viewport-layout>
</div>

