<main class="es-gateway-body">
  <section
    class="es-welcome-layout es-layout-columns -es-2-column es-gateway-column -es-flex-horizontal"
  >
  
    <div class="es-column"
      [ngClass]="(esColumnClass) ? esColumnClass : 'es-visit-assessment-form'"
    >
      <div class="es-content-box">
        <div class="es-panel es-custom-panel es-welcome-panel">
          <div class="es-form">
            <div class="es-content-box">
              <div class="es-text-group">
                  <ng-content select="[cardBody]"></ng-content>

                  <ng-content slot="sheet-content"></ng-content>
                  <!-- <div class="es-card -es-data">
                    <header class="es-card-header">
                      <h2>{{ headerText }}</h2>
                    </header>

                    <ng-content select="[cardBody]"></ng-content>

                    <ng-content slot="sheet-content"></ng-content>
                  </div> -->
                  <!-- <ng-content slot="esBottomToolbar"></ng-content> -->
                  <ng-content select="[esBottomToolbar]"></ng-content>

                  <div class="es-toolbar" [ngClass]="{'custom-toolbar': !(secondaryActionText || primaryActionText)}">
                    <ul class="es-right">
                      <li class="es-toolbar-item">
                        <button
                          class="es-button custom-button"
                          *ngIf="secondaryActionText"
                          id="secondary-button-id"
                          mat-flat-button
                          class=""
                          (click)="secondaryAction.emit()"
                        >
                          {{ secondaryActionText }}
                        </button>
                      </li>
                      <li class="es-toolbar-item">
                        <button
                          class="es-button -es-primary custom-button"
                          *ngIf="primaryActionText"
                          id="primary-button-id"
                          color="primary"
                          (click)="primaryAction.emit()"
                        >
                          {{ primaryActionText }}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          
          <!-- <div class="es-toolbar">
              <ul class="es-right">
                <li class="es-toolbar-item">
                  
                  <button
                    class="es-button  -es-danger "
                    id="endButton"
                    type="submit"
                    
                  >
                    End Session
                  </button>
                </li>
                <li class="es-toolbar-item">
                  
                  <button
                    class="es-button"
                    id="reqButton"
                    type="submit"
                    
                  >
                    Request Control
                  </button>
                </li>
              </ul>
            </div> -->
        </div>
      </div>
    </div>

    <!-- <div class="es-column">
      <div class="es-content-box">
        <div class="es-panel es-welcome-panel">
          <div class="es-form">
            <div class="es-content-box">
              <div class="es-text-group">
                <h2>{{ headerText }}</h2>
                <ng-content slot="sheet-content"></ng-content>

                <div class="es-toolbar">
                  <ul class="es-right">
                    <li class="es-toolbar-item">
                      
                      <button
                        class="es-button"
                        *ngIf="secondaryActionText"
                        id="secondary-button-id"
                        mat-flat-button
                        class=""
                        (click)="secondaryAction.emit()"
                      >
                        {{ secondaryActionText }}
                      </button>
                    </li>

                    <li class="es-toolbar-item">
                      
                      <button
                        class="es-button  -es-primary "
                        *ngIf="primaryActionText"
                        id="primary-button-id"
                        color="primary"
                        (click)="primaryAction.emit()"
                      >
                        {{ primaryActionText }}
                      </button>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </section>
</main>

<!-- <mat-card fxLayout="column">
  <mat-card-header *ngIf="headerText">
    <h1 class="mat-headline">{{ headerText }}</h1>
  </mat-card-header>
  <mat-card-content [style.overflow]="'auto'" fxFlex="1 auto">
    <ng-content slot="sheet-content"></ng-content>
  </mat-card-content>
  <mat-card-actions align="end">
    <button
      *ngIf="secondaryActionText"
      id="secondary-button-id"
      mat-flat-button
      (click)="secondaryAction.emit()"
    >
      {{ secondaryActionText }}
    </button>
    <button
      *ngIf="primaryActionText"
      id="primary-button-id"
      mat-flat-button
      color="primary"
      (click)="primaryAction.emit()"
    >
      {{ primaryActionText }}
    </button>
  </mat-card-actions>
</mat-card> -->
