<div class="es-gateway" *transloco="let transl">
  <!-- Loading bar overlay -->
  <vt-ui-loading-overlay *ngIf="isLoading$ | async"></vt-ui-loading-overlay>
  
  <!-- Header -->
  
  <header class="es-gateway-header">
    <div class="header-inner">
      <div class="es-logo">
        <img class="logo-clario" src="/assets/images/clario-new-logo.svg" alt="Clario-logo">
      </div>
      <div class="product-name">{{ transl('user.account.title_proper_case') }}</div>
      <div class="ellipses-1"></div>
      <div class="ellipses-2"></div>
      <div class="ellipses-3"></div>
    </div>
  </header>
  
  <!-- Language select drop down -->
  <div fxLayout="row" fxLayoutAlign="flex-end" class="es-lang-select">
    <vt-ui-language-select class="top-right"></vt-ui-language-select>
  </div>

  <main class="es-gateway-body">
    <section
      *transloco="let transl"
      class="es-layout-columns -es-2-column es-gateway-column es-secirity-que-layout -es-flex-horizontal"
    >
      <div class="es-column es-security-column">
        <div class="es-content-box">
          <div class="es-panel es-custom-panel security-que-panel">
            <div class="es-form">
              <div class="es-content-box">
                <div class="es-text-group">
                  <h2>{{ transl('security_questions.title') }}</h2>
                  <p>{{ transl('security_questions.subtitle') }}</p>
                </div>
              </div>
              <div class="security-que-ans-form">
                <form class="form" [formGroup]="formGroup">
                  <vt-activate-security-question-answer
                    [answerFormControl]="getFormControl('question1Answer')"
                    [questionFormControl]="getFormControl('question1')"
                    [label]="transl('security_questions.question_one')"
                    [questionOptions$]="question1Options$"
                    [showRequiredErrorForAnswer]="
                      answerControlHasRequiredError('question1Answer')
                    "
                    [showLengthErrorForAnswer]="
                      answerControlHasLengthError('question1Answer')
                    "
                    [showErrorForQuestion]="questionControlHasErrors('question1')"
                  >
                  </vt-activate-security-question-answer>
                  <vt-activate-security-question-answer
                    [answerFormControl]="getFormControl('question2Answer')"
                    [questionFormControl]="getFormControl('question2')"
                    [label]="transl('security_questions.question_two')"
                    [questionOptions$]="question2Options$"
                    [showRequiredErrorForAnswer]="
                      answerControlHasRequiredError('question2Answer')
                    "
                    [showLengthErrorForAnswer]="
                      answerControlHasLengthError('question2Answer')
                    "
                    [showErrorForQuestion]="questionControlHasErrors('question2')"
                  >
                  </vt-activate-security-question-answer>
                  <vt-activate-security-question-answer
                    [answerFormControl]="getFormControl('question3Answer')"
                    [questionFormControl]="getFormControl('question3')"
                    [label]="transl('security_questions.question_three')"
                    [questionOptions$]="question3Options$"
                    [showRequiredErrorForAnswer]="
                      answerControlHasRequiredError('question3Answer')
                    "
                    [showLengthErrorForAnswer]="
                      answerControlHasLengthError('question3Answer')
                    "
                    [showErrorForQuestion]="questionControlHasErrors('question3')"
                  >
                  </vt-activate-security-question-answer>
                </form>
              </div>
              <div class="es-toolbar es-secuirty-toolbar">
                <ul class="es-right">
                  <li class="es-toolbar-item">
                    <button
                      id="cancelButton"
                      class="es-button"
                      (click)="handleCancelClick()"
                    >
                      {{ transl('general.cancel') }}
                    </button>
                  </li>
                  <li class="es-toolbar-item">
                    <button
                      id="nextButton"
                      type="submit"
                      class="es-button  -es-primary "
                      (click)="handleFormSubmit()"
                    >
                      {{ transl('general.next') }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>