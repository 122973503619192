<div class="es-gateway" *transloco="let transl">
  <!-- Loading bar overlay -->
  <vt-ui-loading-overlay *ngIf="isLoading$ | async"></vt-ui-loading-overlay>

  <!-- Header -->
  <header class="es-gateway-header">
    <div class="header-inner">
      <div class="es-logo">
        <img class="logo-clario" src="/assets/images/clario-new-logo.svg" alt="Clario-logo">
      </div>
      <div class="product-name">{{ transl('user.account.title_proper_case') }}</div>
      <div class="ellipses-1"></div>
      <div class="ellipses-2"></div>
      <div class="ellipses-3"></div>
    </div>
  </header>

  <!-- Language select drop down -->
  <div fxLayout="row" fxLayoutAlign="flex-end" class="es-lang-select">
    <vt-ui-language-select class="top-right"></vt-ui-language-select>
  </div>

  <!-- Reset Password screen START -->
  <main class="es-gateway-body">
    <section *transloco="let transl" class="reset-password-layout es-layout-columns -es-2-column es-gateway-column -es-flex-horizontal">
      <div class="es-column">
        <div class="es-content-box">
          <div class="es-panel panel-padded">
            <div class="es-form">
              <div class="es-content-box">
                <h2>{{ transl('user.account.reset_password') }}</h2>
              </div>
              <div class="es-content-box">
                <div class="es-inline-message inline-message-reset-password">
                  <span class="es-icon es-icon-info-circle"></span>
                  <div>
                    <p>{{ transl('text.reset_password.instruction') }}</p>
                    <ul>
                      <li>{{ transl('text.reset_password.options.one') }}</li>
                      <li>{{ transl('text.reset_password.options.two') }}</li>
                      <li>{{ transl('text.reset_password.options.three') }}</li>
                      <li>{{ transl('text.reset_password.options.four') }}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <form class="form" [formGroup]="formGroup" class="form-spaced">
                <vt-account-security-question-answer [answerFormControl]="answerFormControl"
                  [showAnswerLengthError]="answerControlLengthError"
                  [showAnswerRequiredError]="answerControlRequiredError"
                  [showNoAnswerMatchApiError]="answerControlNoMatchApiError" [securityQuestion]="securityQuestion">
                </vt-account-security-question-answer>
                <vt-account-confirm-password [confirmPasswordControl]="confirmPasswordFormControl"
                  [confirmPasswordControlMatchError]="confirmPasswordControlMatchError"
                  [confirmPasswordControlRequiredError]="confirmPasswordControlRequiredError"
                  [passwordFormControl]="passwordFormControl"
                  [passwordControlCriteriaError]="passwordControlCriteriaError"
                  [passwordControlRequiredError]="passwordControlRequiredError">
                </vt-account-confirm-password>
              </form>

              <div class="es-toolbar">
                <ul class="es-right">
                  <li class="es-toolbar-item">
                    <button class="es-button" (click)="handleBackToLoginClick()">
                      {{ transl('general.cancel') }}
                    </button>
                  </li>
                  <li class="es-toolbar-item">
                    <button class="es-button -es-primary" id="submitButtonId" [disabled]="maxAnswerAttemptsReached"
                      (click)="handleSubmitClick()">
                      {{ transl('general.submit') }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <vt-account-reset-password-max-attempts
          [maxAnswerAttemptsReached]="maxAnswerAttemptsReached"
          (newResetPasswordRequestLinkClick)="handleNewResetPasswordRequestLinkClick()"
          (backToLoginClick)="handleBackToLoginClick()">
        </vt-account-reset-password-max-attempts>
      </div>
    </section>
  </main>
  <!-- Reset Password screen END -->
</div>